'use client';

import { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  MdOutlineChecklist,
  MdLoop,
  MdOutlineInfo,
  MdOutlinePayment
} from 'react-icons/md';
import { HiOutlineBuildingStorefront } from 'react-icons/hi2';
import { BsTruck } from 'react-icons/bs';
import { FaChevronDown } from 'react-icons/fa';
import styles from './helpSection.module.scss';

import { Text } from '@/components/atoms';
import FaqSection from '@/components/molecules/FaqSection';
import StoreBranches from '@/components/templates/Help/Help/StoreBranches';

const HelpSection = (props) => {
  const {
    faqs = [],
    title = '',
    slug = '',
    selected = false,
    stores = {}
  } = props;
  const [open, setOpen] = useState(selected);
  const [scroll, setScroll] = useState(0);
  const [storeSelect, setStoreSelect] = useState(0);
  const active = useSelector((state) => state.faqs.active);
  const sectionRef = useRef(null);
  const faqsRef = useRef(null);
  const chevronRef = useRef(null);

  const logo = (arg) => {
    switch (arg) {
      case 'terminos-y-condiciones':
        return <MdOutlineChecklist fontSize={30} />;

      case 'compras-y-envios':
        return <BsTruck fontSize={30} />;

      case 'cambios-y-devoluciones':
        return <MdLoop fontSize={30} />;

      case 'sucursales':
        return <HiOutlineBuildingStorefront fontSize={30} />;

      case 'pagos-y-promociones':
        return <MdOutlinePayment fontSize={30} />;

      default:
        return <MdOutlineInfo fontSize={30} />;
    }
  };

  const setFaqs = () => {
    setStoreSelect(faqsRef.current.scrollHeight);
  };

  if (open && sectionRef.current)
    sectionRef.current.style.height = `${
      sectionRef.current.scrollHeight + scroll
    }px`;

  // if (slug === 'sucursales' && open && sectionRef.current && storeSelect !== 0)
  //   sectionRef.current.style.height = `${faqsRef.current.scrollHeight}px`;

  useEffect(() => {
    if (
      slug === 'sucursales' &&
      open &&
      sectionRef.current &&
      storeSelect !== 0
    )
      sectionRef.current.style.height = `${faqsRef.current.scrollHeight}px`;
  }, [faqsRef?.current?.scrollHeight]);

  const openSection = () => {
    sectionRef.current.style.height = `${sectionRef.current.scrollHeight}px`;
    sectionRef.current.style.borderWidth = '2px';
    chevronRef.current.style.transform = 'rotate(180deg)';
  };

  const closeSection = () => {
    chevronRef.current.style.transform = 'rotate(0deg)';
    sectionRef.current.style.borderWidth = '0px';

    sectionRef.current.style.height = '0px';
  };

  const toggleSection = () => {
    if (!open) {
      openSection();
    } else {
      closeSection();
    }

    setOpen(!open);
  };

  useEffect(() => {
    if (open) {
      openSection();
      window.scrollTo({
        top: chevronRef.current.parentElement.offsetTop - 80,
        behavior: 'smooth'
      });
    }
  }, []);

  return (
    <div className={styles.container}>
      <button
        className={styles.dropdown}
        onClick={() => {
          setScroll(0);
          toggleSection();
        }}
      >
        {logo(slug)}
        <Text
          textColor="var(--color-grey)"
          weight="bold"
        >
          {title}
        </Text>
        <div ref={chevronRef}>
          <FaChevronDown />
        </div>
      </button>
      <div
        ref={sectionRef}
        className={styles.info}
      >
        <div
          className={styles.faqs}
          ref={faqsRef}
        >
          {slug === 'sucursales' ? (
            <StoreBranches
              storebranches={stores}
              onchange={setFaqs}
            />
          ) : (
            faqs.map((f) => (
              <FaqSection
                key={f.title}
                onchange={setScroll}
                title={f.title}
                description={f.description}
                selected={f.slug === active}
              />
            ))
          )}
        </div>
      </div>
    </div>
  );
};

HelpSection.propTypes = {
  faqs: PropTypes.array,
  title: PropTypes.string,
  slug: PropTypes.string,
  selected: PropTypes.bool,
  stores: PropTypes.object
};

export default HelpSection;
