'use client';

import PropTypes from 'prop-types';
import { Divider, Icon, Text } from '@/components/atoms';
import BenefitItem from '@/components/molecules/ProductSheetCard/ProductInfo/BenefitsRibbon/BenefitItem';
import useDeviceType from '@/hooks/useDeviceType';
import styles from './benefitsRibbon.module.scss';

const BenefitsRibbon = ({
  shippingDelay = '',
  province = '',
  zipCode = ''
}) => {
  const { isDesktop } = useDeviceType();
  return (
    <div className={styles.ribbon}>
      <BenefitItem
        logo={
          <Icon
            type="truck"
            color="white"
            reverse
            width={isDesktop ? '40px' : '30px'}
          />
        }
        text={
          <div>
            <Text size="sm">
              Envío a {province || 'Mendoza'} ({zipCode || '5500'}):{' '}
              <b style={{ color: 'var(--color-primary)' }}>
                {shippingDelay.cost_total === '0,00'
                  ? 'Gratis'
                  : `$${shippingDelay.cost_total}`}
              </b>
            </Text>
            <Text size="sm">{shippingDelay.message}</Text>
          </div>
        }
      />
      {!isDesktop && (
        <Divider
          color="var(--color-white)"
          inherit
          otherSize="2px"
          colored={!isDesktop}
          style={{ borderRadius: '50px', minWidth: '2px' }}
        />
      )}
      <BenefitItem
        logo={
          <Icon
            type="store"
            color="white"
            width={isDesktop ? '40px' : '30px'}
          />
        }
        text="Cambios ilimitados en Sucursal"
      />
      {!isDesktop && (
        <Divider
          color="var(--color-white)"
          inherit
          otherSize="2px"
          colored={!isDesktop}
          style={{ borderRadius: '50px', minWidth: '2px' }}
        />
      )}
      <BenefitItem
        logo={
          <Icon
            type="change"
            color="white"
            width={isDesktop ? '40px' : '30px'}
          />
        }
        text="1° cambio gratis a domicilio"
      />
    </div>
  );
};
BenefitsRibbon.propTypes = {
  shippingDelay: PropTypes.string,
  province: PropTypes.string,
  zipCode: PropTypes.string
};

export default BenefitsRibbon;
