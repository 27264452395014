'use client';

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import PropTypes from 'prop-types';
import cn from 'classnames';
import { BiMinus, BiPlus } from 'react-icons/bi';
import { useState, useRef, useEffect, Component } from 'react';
import styles from './navbarSection.module.scss';
import { Link, Text, Image } from '@/components/atoms';

const NavbarSection = (props) => {
  const {
    allURL,
    onClose,
    sectionRef,
    items = [],
    title = '',
    selected = false
  } = props;
  const isBrandCategory = title === 'Marcas Destacadas';
  const [open, setOpen] = useState(selected);
  const infoRef = useRef(null);
  const verticalRef = useRef(null);
  const horizontalRef = useRef(null);
  const infoFaqClass = cn({
    [styles.faqInfo]: true,
    [styles.brandWrapper]: isBrandCategory
  });

  const itemsStyles = cn({
    [styles.brandsItems]: isBrandCategory
  });
  const openInfo = () => {
    if (!open) {
      infoRef.current.style.height = `${infoRef.current.scrollHeight}px`;
      sectionRef.current.style.height = `${
        sectionRef.current.scrollHeight + infoRef.current.scrollHeight
      }px`;

      verticalRef.current.style.transform = 'rotate(360deg)';
      horizontalRef.current.style.opacity = '0';
      horizontalRef.current.style.transform = 'rotate(360deg)';
      infoRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    } else {
      verticalRef.current.style.transform = 'rotate(0deg)';
      horizontalRef.current.style.opacity = '1';

      horizontalRef.current.style.transform = 'rotate(0deg)';
      infoRef.current.style.height = '0px';
      sectionRef.current.style.height = `${
        sectionRef.current.scrollHeight - infoRef.current.scrollHeight
      }px`;
    }
    setOpen(!open);
  };

  useEffect(() => {
    if (selected) openInfo();
  }, [infoRef.current]);
  return (
    <div className={styles.faq}>
      <div className={styles.titleSection}>
        <button
          className={styles.faqTitle}
          onClick={() => openInfo()}
        >
          <Text>{title}</Text>
          <div className={styles.icon}>
            <div
              ref={verticalRef}
              className={styles.vertical}
            >
              <BiMinus fontSize={15} />
            </div>
            <div
              ref={horizontalRef}
              className={styles.horizontal}
            >
              <BiPlus fontSize={15} />
            </div>
          </div>
        </button>
      </div>
      <ul
        ref={infoRef}
        className={infoFaqClass}
      >
        {items.map(
          (i) =>
            (isBrandCategory ? i.icon_white_url : !isBrandCategory) && (
              <li
                key={i.name}
                onClick={onClose}
              >
                <Link
                  href={`/${i.url}`}
                  linkStyle={{ color: 'white', marginTop: '5px' }}
                  className={itemsStyles}
                >
                  {isBrandCategory && (
                    <Image
                      alt={i.name}
                      width={80}
                      height={40}
                      cover
                      src={i.icon_white_url}
                    />
                  )}
                  <div>{i.name}</div>
                </Link>
              </li>
            )
        )}
        <Link
          href={`${allURL[0] === '/' ? '' : '/'}${allURL}`}
          primary
          linkStyle={{ marginBlock: '10px' }}
        >
          <li onClick={onClose}>Ver todo</li>
        </Link>
      </ul>
    </div>
  );
};

NavbarSection.propTypes = {
  items: PropTypes.array,
  title: PropTypes.string,
  selected: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  allURL: PropTypes.string.isRequired,
  sectionRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Component) })
  ]).isRequired
};

export default NavbarSection;
