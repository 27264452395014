'use client';

import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Slider from '@/components/molecules/Slider';
import useDeviceType from '@/hooks/useDeviceType';
import styles from './categorySlider.module.scss';
import CategoryCard from '../CategoriesGroup/CategoryGrid/CategoryCard';

const CategoriesSlider = ({ categories }) => {
  const { isDesktop } = useDeviceType();
  const [sliderWidth, setSliderWidth] = useState(0);

  useEffect(() => {
    let windowWidth;
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      windowWidth = window.innerWidth || document.documentElement.clientWidth;
    }

    const categoryWidth = isDesktop ? 300 : 170;
    const gap = 8;

    let calculatedSliderWidth = categoryWidth;
    let calculatedCategoriesInScreen = 1;

    while (
      calculatedSliderWidth + categoryWidth + gap < windowWidth &&
      calculatedCategoriesInScreen < categories.length
    ) {
      calculatedCategoriesInScreen++;
      calculatedSliderWidth =
        (categoryWidth + gap) * calculatedCategoriesInScreen - gap;
    }

    setSliderWidth(calculatedSliderWidth);
  }, [isDesktop]);
  return categories?.length > 0 ? (
    <div className={styles.categoriesContainer}>
      <Slider width={`${sliderWidth}px`}>
        {categories.map((c) => (
          <CategoryCard
            title={c.name}
            imageURL={c.image?.url}
            link={`/${c.slug}`}
          />
        ))}
      </Slider>
    </div>
  ) : null;
};
CategoriesSlider.propTypes = {
  categories: PropTypes.array.isRequired
};
export default CategoriesSlider;
