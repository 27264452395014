import axios from 'axios';
import errorHandler from '../../services/error/handler';

const getProvinceLocations = async (province) => {
  try {
    const response = await axios.get(
      `https://apis.andreani.com/v1/localidades?provincia=${province}`
    );
    const responseData = response.data;
    const data = [];
    responseData.forEach((l) => {
      if (!data.find((d) => d.name === l.partido)) {
        data.push({
          name: l.partido,
          value: l.partido,
          id: l.partido,
          location: [
            {
              name: l.localidad,
              value: l.localidad,
              id: l.localidad,
              postalCode: l.codigosPostales[0]
            }
          ]
        });
      } else if (
        !data
          .find((d) => d.name === l.partido)
          .location.find((L) => L.name === l.localidad)
      ) {
        data
          .find((d) => d.name === l.partido)
          .location.push({
            name: l.localidad,
            value: l.localidad,
            id: l.localidad,
            postalCode: l.codigosPostales[0]
          });
      }
    });

    return {
      success: true,
      data
    };
  } catch (err) {
    const message = errorHandler(err);
    return {
      success: false,
      error: message
    };
  }
};

const getProvinceInfoByZipCode = async (zipCode, multiple = false) => {
  try {
    const response = await axios.get(
      `https://apis.andreani.com/v1/localidades?codigosPostales=${zipCode}`
    );
    const responseData = response.data;
    const data = {
      province: responseData[0].provincia,
      department: responseData[0].partido
    };

    return {
      success: true,
      data: multiple ? response.data : data
    };
  } catch (err) {
    return {
      success: false,
      error: [
        {
          error_description: err?.response?.data?.detail,

          error_type: 'zip_code'
        }
      ]
    };
  }
};

export default { getProvinceLocations, getProvinceInfoByZipCode };
