'use client';

import { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Icon, Text } from '@/components/atoms';
import styles from './index.module.scss';
import { GeneralModal } from '@/components/molecules';

const ModalFilter = ({ modalState, children, id, label = '' }) => {
  const [close, setClose] = useState(false);
  const storeId = useSelector((state) => state.store);
  const handleCloseModal = () => {
    setClose(true);
    setTimeout(() => {
      modalState[1]({ ...modalState[0], [id]: false });
    }, 300);
  };

  const modalClass = cn({
    [styles.modal]: true,
    [styles.open]: modalState[0][id],
    [styles.close]: close || !modalState[0][id]
  });

  if (storeId === 'city') {
    return (
      <GeneralModal
        fullModal
        closeColor="white"
        onClose={handleCloseModal}
        withAnimations
      >
        <div className={styles.container}>
          <div className={styles.label}>
            <Text
              variant="h2"
              textColor="white"
              weight="bold"
              style={{ fontSize: '20px' }}
            >
              {label}
            </Text>
          </div>

          {children}
        </div>
      </GeneralModal>
    );
  } else {
    return (
      <div
        className={styles.container}
        id={id}
      >
        <div className={modalClass}>
          <div className={styles.label}>
            <Text
              variant="h2"
              textColor="white"
              weight="bold"
              style={{ fontSize: '20px' }}
            >
              {label}
            </Text>
            <button
              onClick={handleCloseModal}
              className={styles.closeIcon}
              aria-label={`Cerrar ${label}`}
            >
              <Icon
                type="close"
                color="var(--color-primary)"
                width="auto"
                fontSize={28}
              />
            </button>
          </div>

          {children}
        </div>
        {modalState[0][id] && (
          // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
          <div
            className={styles.backdrop}
            onClick={handleCloseModal}
          />
        )}
      </div>
    );
  }
};

ModalFilter.propTypes = {
  modalState: PropTypes.array.isRequired,
  label: PropTypes.string,
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired
};

export default ModalFilter;
