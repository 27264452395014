'use client';

import { BiFilterAlt, BiSortDown } from 'react-icons/bi';
import { Suspense, useState } from 'react';
import PropTypes from 'prop-types';
import { usePathname } from 'next/navigation';
import { useSelector } from 'react-redux';
import SelectFilter from '@/components/atoms/SelectFilter';
import { ModalFilter } from '@/components/organisms';
import styles from './index.module.scss';

import { Button, Text, Divider, Link } from '@/components/atoms';

const sortItems = [
  { id: 'mayor_precio', name: 'Precio: Mayor precio', value: 'mayor_precio' },
  { id: 'menor_precio', name: 'Precio: Menor precio', value: 'menor_precio' },
  { id: 'ofertas', name: 'Ofertas', value: 'ofertas' },
  { id: 'lanzamientos', name: 'Lanzamientos', value: 'lanzamientos' }
];

const OptionCheck = ({ selected }) => (
  <div
    style={{
      width: '22px',
      height: '22px',
      borderRadius: '50%',
      border: `solid ${selected ? '5px var(--color-primary)' : '1px white'}  `
    }}
  >
    {selected && (
      <div
        style={{
          width: '100%',
          height: '100%',
          border: '1px solid white',
          borderRadius: '50%'
        }}
      />
    )}
  </div>
);

const storeId = process.env.NEXT_PUBLIC_STORE_ID;

const Filter = (props) => {
  const { isDesktop = false } = props;
  const { filtersOptions } = useSelector((state) => state.products);
  const path = usePathname();
  const modalState = useState({ filter: false, sort: false });
  const getSortUrl = (value) => {
    const existingSort = path.split('/o/');

    const pageIndex = path.indexOf('/p/');
    if (existingSort.length > 1) {
      return `${existingSort[0]}/o/${value}/${existingSort[1]
        .split('/')
        .slice(1)
        .join('/')}`;
    }
    return `${path.slice(0, pageIndex)}/o/${value}${path.slice(pageIndex)}`;
  };

  const renderOptions = () => (
    <ul
      style={{
        color: 'white',
        display: 'flex',
        flexDirection: 'column',
        gap: '15px'
      }}
    >
      {sortItems.map((o) => (
        <li>
          <Link
            absolutePath
            href={getSortUrl(o.value)}
            className={styles.option}
          >
            <OptionCheck selected={path.includes(`/o/${o.value}`)} />
            <Text
              textColor={storeId === 'city' ? 'white' : 'var(--color-grey'}
              style={{ padding: '10px 0' }}
            >
              {o.name}
            </Text>
          </Link>

          <Divider color="grey" />
        </li>
      ))}
    </ul>
  );
  const renderFilterItems = () => {
    return (
      <ul>
        {Object.keys(filtersOptions)
          .filter((option) => filtersOptions[option].options.length > 0)
          .map((option) => (
            <SelectFilter
              key={filtersOptions[option].name}
              label={filtersOptions[option].name}
              options={filtersOptions[option].options}
              sizes={option === 'sizes'}
            />
          ))}
      </ul>
    );
  };
  const renderSelects = () => <div>{renderFilterItems()}</div>;

  return (
    <aside className={styles.container}>
      {!isDesktop && (
        <div>
          <div
            className={`${styles.filtersMobileBtn} ${
              storeId === 'city' && styles.cityStyles
            }`}
          >
            <Button
              id="filerBtn"
              onClick={() => {
                modalState[1]({
                  ...modalState[0],
                  filter: !modalState[0].filter
                });
              }}
              startIcon={
                <BiFilterAlt
                  color={storeId === 'city' ? 'var(--color-grey)' : 'white'}
                  fontSize={23}
                />
              }
              label="Filtrar"
              textColor={storeId === 'city' ? 'var(--color-grey)' : 'white'}
              textStyle={{ fontSize: '1rem' }}
              withOutPadding
              textWeight="semibold"
              variant="transparent"
            />

            <Divider
              size={40}
              color={
                storeId === 'city' ? 'var(--color-grey)' : 'var(--color-primary'
              }
              vertical
            />
            <Button
              id="sortBtn"
              onClick={() => {
                modalState[1]({
                  ...modalState[0],
                  sort: !modalState[0].sort
                });
              }}
              startIcon={
                <BiSortDown
                  color={storeId === 'city' ? 'var(--color-grey)' : 'white'}
                  fontSize={23}
                />
              }
              label="Ordenar"
              textColor={storeId === 'city' ? 'var(--color-grey)' : 'white'}
              textStyle={{ fontSize: '1rem' }}
              withOutPadding
              textWeight="semibold"
              variant="transparent"
            />
          </div>
          <Suspense>
            {modalState[0].filter && (
              <ModalFilter
                id="filter"
                modalState={modalState}
                label="Filtrar Por"
              >
                {renderSelects()}
              </ModalFilter>
            )}
          </Suspense>

          <Suspense>
            {modalState[0].sort && (
              <ModalFilter
                id="sort"
                modalState={modalState}
                label="Ordenar Por"
              >
                {renderOptions()}
              </ModalFilter>
            )}
          </Suspense>
        </div>
      )}
      <div className={styles.content}>
        {isDesktop && filtersOptions && renderSelects()}
      </div>
    </aside>
  );
};
OptionCheck.propTypes = {
  selected: PropTypes.bool.isRequired
};
Filter.propTypes = {
  isDesktop: PropTypes.bool
};

export default Filter;
