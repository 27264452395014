'use client';

/* eslint-disable consistent-return */
/* eslint-disable no-plusplus */
import React, { useEffect, useState } from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import styles from './card-preview.module.scss';

const cx = classnames.bind(styles);

const CardPreview = ({
  number = '',
  numberGaps = [],
  numberLength = null,
  brand = '',
  securityCode = null,
  nameSurname = '',
  expDate = '',
  currentInput = '',
  issuer = ''
}) => {
  const [rotateBack, setRotateBack] = useState(false);

  const newNumber =
    number?.length < numberGaps[0]
      ? number.toString().replace(/ /g, '')
      : number.trim();

  useEffect(() => {
    if (currentInput === 'securityCode' && brand !== 'american-express') {
      setRotateBack(true);
    } else {
      setRotateBack(false);
    }
  }, [currentInput]);

  const returnCurrentInput = () => {
    if (currentInput === 'docNumber') {
      return 'docNumber';
    }
    if (currentInput === 'birthDate') {
      return 'birthDate';
    }
  };

  const containerClass = cx({
    container: true,
    visible: currentInput !== returnCurrentInput()
  });

  const rotateClass = cx({
    rotate: true,
    'rotate__rotate-back': rotateBack,
    'rotate__rotate-front': !rotateBack
  });

  const brandClass = {
    'card--issuer-naranja': issuer === 'naranja',
    'card--issuer-mercado-pago': issuer === 'mercado-pago',
    'card--issuer-uala': issuer === 'uala',
    'card--issuer-cmr': issuer === 'cmr',
    'card--issuer-cencosud': issuer === 'cencosud',
    'card--issuer-cencosud-giftcard': issuer === 'cencosud-giftcard',
    'card--issuer-montemar': issuer === 'montemar',
    'card--issuer-icbc': issuer === 'icbc',
    'card--issuer-galicia': issuer === 'galicia',
    'card--issuer-nacion':
      issuer === 'nacion' || issuer === 'nacion-tarjeta-social',
    'card--issuer-san-juan': issuer === 'san-juan',
    'card--issuer-itau': issuer === 'itau',
    'card--issuer-macro': issuer === 'macro',
    'card--issuer-santander': issuer === 'santander',
    'card--issuer-bbva': issuer === 'bbva',
    'card--issuer-provencred': issuer === 'provencred',
    'card--issuer-visa': issuer === 'visa-no-bancaria' || issuer === 'visa',
    'card--issuer-credicoop': issuer === 'credicoop',
    'card--issuer-supervielle': issuer === 'supervielle',
    'card--issuer-comafi': issuer === 'comafi',
    'card--issuer-hipotecario': issuer === 'hipotecario',
    'card--issuer-mastercard': issuer === 'mastercard',
    'card--issuer-maestro': issuer === 'maestro',
    'card--issuer-naranja-x': issuer === 'naranja-x',
    'card--issuer-brubank': issuer === 'brubank',
    'card--issuer-ultra': issuer === 'ultra',

    'card--brand-mastercard': brand === 'mastercard' && issuer !== 'mastercard',
    'card--brand-mastercard-debito':
      brand === 'mastercard-debit' && issuer !== 'mastercard',
    'card--brand-visa':
      brand === 'visa' && issuer !== 'visa' && issuer !== 'visa-no-bancaria',
    'card--brand-visa-debito': brand === 'visa-debit',
    'card--brand-american-express': brand === 'american-express',
    'card--brand-maestro': brand === 'maestro',
    'card--brand-cabal': brand === 'cabal',
    'card--brand-cabal-debito': brand === 'cabal-debit',
    'card--brand-nativa': brand === 'nativa',
    'card--brand-ultra': brand === 'ultra'
  };

  const cardFrontClass = cx({
    card: true,
    card__front: true,
    ...brandClass
  });

  const cardBackClass = cx({
    card: true,
    card__back: true,
    ...brandClass
  });

  const securityCircleClass = cx({
    'card__security--circle': true,
    'card__security--circle--focus': currentInput === 'securityCode'
  });

  const renderNumberWithPlaceholder = () => {
    const numberWithoutSpaces = number.replace(/ /g, '');
    const max = numberLength;
    const start = numberWithoutSpaces.length;

    const characters = [];

    for (let x = start; x < max; x++) {
      const addSpace = numberGaps.indexOf(x) !== -1;

      if (addSpace) {
        characters.push(
          <span
            key={x}
            className={cx(
              'card__number--placeholder--point',
              'card__number--placeholder--point-space'
            )}
          >
            *
          </span>
        );
      } else {
        characters.push(
          <span
            key={x}
            className={cx('card__number--placeholder--point')}
          >
            *
          </span>
        );
      }
    }

    return characters;
  };

  return (
    <div className={containerClass}>
      <div className={rotateClass}>
        {/* Front */}
        <div className={cardFrontClass}>
          {/* Background */}
          <div className={cx('card__background')} />
          <div className={cx('card__background-overlay')} />

          <div className={cx('card__logo')}>
            {/* Logo tarjeta */}
            <div className={cx('card__logo--left')} />
            <div className={cx('card__logo--right')} />
          </div>

          {/* Cod. seguridad */}
          <div className={cx('card__security')}>
            {brand === 'american-express' && (
              <span className={cx('card__security--front')}>
                <span className={cx('card__security--value')}>
                  {securityCode}
                </span>
                <span className={securityCircleClass} />
              </span>
            )}
          </div>

          {/* Numero */}
          <div className={cx('card__number')}>
            <span className={cx('card__number--value')}>{newNumber}</span>
            <div className={cx('card__number--placeholder')}>
              {renderNumberWithPlaceholder()}
            </div>
          </div>

          <div className={cx('row')}>
            {/* Nombre y apellido */}
            <span className={cx('card__name')}>{nameSurname}</span>

            {/* Fecha expiracion */}
            <span className={cx('card__expiration')}>{expDate}</span>
          </div>
        </div>

        {/* Back */}
        <div className={cardBackClass}>
          {/* Background */}
          <div className={cx('card__background')} />
          <div className={cx('card__background-overlay')} />

          {/* Banda magnetica */}
          <div className={cx('card__magnetic')} />

          <div className={cx('row')}>
            {/* Cod. seguridad */}
            <div className={cx('card__security')}>
              <span className={cx('card__security--back')}>
                <span className={cx('card__security--value')}>
                  {securityCode}
                </span>
                <span className={securityCircleClass} />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

CardPreview.propTypes = {
  number: PropTypes.string,
  numberGaps: PropTypes.array,
  numberLength: PropTypes.number,
  brand: PropTypes.string,
  securityCode: PropTypes.string,
  nameSurname: PropTypes.string,
  expDate: PropTypes.string,
  currentInput: PropTypes.string,
  issuer: PropTypes.string
};

export default CardPreview;
