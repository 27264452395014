/* eslint-disable jsx-a11y/click-events-have-key-events */
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Icon } from '@/components/atoms';

const StarIcon = ({
  index,
  number,
  onClick = () => {},
  onMouse = () => {},
  full = false
}) => {
  const [pint] = useState(0);
  const [hover] = useState(0);

  const condition = () => {
    if (full || pint >= index || hover >= index || number - index > -0.5) {
      return (
        <div
          id="full-star"
          style={{ width: '20px', height: '20px' }}
        >
          <Icon
            type="full-star"
            colored="var(--color-primary)"
          />
        </div>
      );
    }
    if (number - index > -1) {
      return (
        <div
          id="half-star"
          style={{ width: '20px', height: '20px' }}
        >
          <Icon
            type="half-star"
            colored="var(--color-primary)"
          />
        </div>
      );
    }
    return (
      <div
        id="empty-star"
        style={{ width: '20px', height: '20px' }}
      >
        <Icon type="empty-star" />
      </div>
    );
  };
  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      id={index}
      onClick={(e) => onClick && onClick(e)}
      onMouseEnter={onMouse ? () => onMouse(index, false) : null}
      onMouseLeave={onMouse ? () => onMouse(index, true) : null}
    >
      {condition()}
    </div>
  );
};

StarIcon.propTypes = {
  index: PropTypes.number.isRequired,
  number: PropTypes.number.isRequired,
  onClick: PropTypes.func,
  onMouse: PropTypes.func,
  full: PropTypes.bool
};

export default StarIcon;
