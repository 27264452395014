'use client';

import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import { Select, Text } from '@/components/atoms';
import Title from '@/components/molecules/Title';
import styles from './quantitySelector.module.scss';
import { setQuantity } from '@/redux/productDetail/productDetailReducer';

const QuantitySelector = ({ variantSelected = {}, quantitySelected = 1 }) => {
  const dispatch = useDispatch();

  const getOptions = () => {
    const quantity = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 1; i <= variantSelected?.count_on_hand; i++) {
      quantity.push({ value: i, name: `${i} Unidad` });
    }
    return quantity;
  };

  const [options, setOptions] = useState([]);
  useEffect(() => {
    setOptions(getOptions());
    dispatch(setQuantity(1));
  }, [variantSelected]);

  const handleSelectQuantity = (quantity) => {
    dispatch(setQuantity(quantity.value));
  };

  return variantSelected?.count_on_hand ? (
    <div className={styles.container}>
      <Title
        icon={
          <>
            <Text
              variant="h2"
              textSize="m"
              weight="light"
            >
              Cantidad
            </Text>
            {variantSelected.count_on_hand && (
              <>
                <Text
                  variant="span"
                  textSize="m"
                  weight="bold"
                >
                  {quantitySelected} unid.
                </Text>
                <Text
                  variant="span"
                  textSize="s"
                  weight="light"
                >
                  ({variantSelected.count_on_hand}
                  {variantSelected.count_on_hand > 1
                    ? ' disponibles'
                    : ' disponible'}
                  )
                </Text>
              </>
            )}
          </>
        }
        dividerSeparation="1rem"
      />
      <div className={styles.list}>
        {variantSelected.count_on_hand ? (
          <Select
            id="9"
            brColor="var(--color-white)"
            color="var(--color-white)"
            name="quantity"
            options={options}
            variant="outlined"
            className="halfWidth"
            onSelect={handleSelectQuantity}
            defaultValue={options[0]?.value}
            required
          />
        ) : (
          <Text textStyle={{ height: '3rem' }}>Selecciona la cantidad</Text>
        )}
      </div>
    </div>
  ) : (
    <Text
      textStyle={{ height: '3rem', fontWeight: '400' }}
      textColor="red"
    >
      Selecciona un talle
    </Text>
  );
};

QuantitySelector.propTypes = {
  variantSelected: PropTypes.object,
  quantitySelected: PropTypes.number
};

export default QuantitySelector;
