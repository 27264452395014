const getSlugsWithoutQuerys = (regexToDelete, landing, slugs = []) => {
  /*eslint-disable*/
  slugs = `/${slugs.join('/')}`;

  [...regexToDelete, new RegExp(`/${landing}`)]?.forEach((regex) => {
    slugs = slugs.replace(new RegExp(regex), '');
  });
  const arrOfSlugs = slugs.split('/');
  if (!arrOfSlugs[0]) arrOfSlugs.shift();
  return slugs === '/' ? [] : arrOfSlugs;
};

const getSpecialSlugs = (slugs = []) => {
  slugs = `/${slugs?.join('/')}`;
  const keywordsIndex = {
    initialIndex: slugs.match(/\/k\//)?.index,
    name: 'keywords',
    slug: 'k'
  };

  const pageIndex = {
    initialIndex: slugs.match(/\/p\/\d/)?.index,
    name: 'page',
    slug: 'p'
  };
  const talleIndex = {
    initialIndex: slugs.match(/\/t\//)?.index,
    name: 'talle',
    slug: 't'
  };
  const ordenarIndex = {
    initialIndex: slugs.match(/\/o\//)?.index,
    name: 'ordenar',
    slug: 'o'
  };

  const specialSlugsInformationSorted = [];
  if (keywordsIndex.initialIndex >= 0)
    specialSlugsInformationSorted.push(keywordsIndex);
  if (pageIndex.initialIndex >= 0)
    specialSlugsInformationSorted.push(pageIndex);
  if (talleIndex.initialIndex >= 0)
    specialSlugsInformationSorted.push(talleIndex);
  if (ordenarIndex.initialIndex >= 0)
    specialSlugsInformationSorted.push(ordenarIndex);
  //Ordenamiento de los slugs especiales en función de su index
  specialSlugsInformationSorted.sort((a, b) => a.initialIndex - b.initialIndex);

  const specialSlugs = {};
  specialSlugsInformationSorted?.forEach((q, i) => {
    specialSlugs[q.name] = slugs.slice(
      q?.initialIndex + 3,
      specialSlugsInformationSorted[i + 1]?.initialIndex ||
        slugs.indexOf('/', q?.initialIndex + 3) > -1
        ? slugs.indexOf('/', q?.initialIndex + 3)
        : undefined
    );
  });
  //Regex de los slugs ordenados que deben borrarse luego para hacer la búsqueda de productos
  specialSlugs.slugsRegexToDelete = specialSlugsInformationSorted.map(
    (q) =>
      new RegExp(
        `/${[q.slug]}/${specialSlugs[q.name]}|${[q.slug]}/${
          specialSlugs[q.name]
        }`
      )
  );

  return specialSlugs;
};

const getProductData = (
  product,
  slug,
  id,
  colorSelected,
  eventId = '',
  cardPromos
) => {
  const {
    gender,
    name,
    brand_icon,
    brand_name,
    sport,
    colors,
    reviews_count: reviewsCount,
    avg_rating: avgRating,
    reviews,
    business,
    is_wish_product: isWishProduct,
    embeds: embeddeds,
    category_name,
    featured,
    shipping_delay: shippingDelay
  } = product;
  let {
    prices: { original: price, discount, installments, sale_price },
    id: idColor,
    images,
    color,
    sku,
    variants,
    description,
    properties
  } = colorSelected;
  const strikePrice = sale_price ? price : null;
  price = sale_price || price;
  slug = slug.replace(/-\d+$/, '');

  return {
    id,
    idColor,
    slug,
    gender,
    name,
    color,
    brand_icon,
    brand_name,
    sport,
    colors,
    price,
    installments,
    strikePrice,
    discount,
    images,
    business,
    sku,
    variants,
    description,
    properties,
    valorations: { avgRating, reviews, reviewsCount },
    eventId,
    category_name,
    isWishProduct,
    shippingDelay,
    embeddeds:
      Object.keys(embeddeds || {}).length > 0
        ? embeddeds
        : { youtube: [], render: [] },
    cardPromos,
    featured
  };
};

export { getSlugsWithoutQuerys, getSpecialSlugs, getProductData };
