const isGender = (gender) => {
  const genders = {
    hombre: 'hombre',
    mujer: 'mujer',
    nino: 'nino',
    unisex: 'unisex'
  };

  return genders[
    gender
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
  ];
};

export default isGender;
