'use client';

import { useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import styles from './storebranches.module.scss';
import StoreBranchList from '@/components/templates/Help//Help/StoreBranches/StoreBranchList';
import { Image } from '@/components/atoms';

const StoreBranches = ({ storebranches, onchange = () => {} }) => {
  const existStores = Object.keys(storebranches).length !== 0;
  const map = useRef(null);

  const { active, lat, lng } = useSelector((state) => state.map);

  return (
    existStores && (
      <div className={styles.wrapper}>
        <div className={styles.storebranchList}>
          <StoreBranchList
            map={map}
            storebranches={storebranches}
            onchange={onchange}
          />
        </div>
        <div
          id="mapa"
          ref={map}
          className={styles.content}
        >
          {lat && lng ? (
            <iframe
              title="mapa"
              width="100%"
              allowFullScreen={false}
              height="100%"
              frameBorder="0"
              src={`https://www.google.com/maps/embed/v1/place?key=${process.env.NEXT_PUBLIC_GOOGLE_API_KEY}&q=${lat},${lng}&zoom=16`}
            />
          ) : (
            <Image
              src={`/maps/${active}.png`}
              imageStyles={{ width: '100%', height: '100%' }}
              cover
              alt="mapa de ubicacion"
            />
          )}
        </div>
      </div>
    )
  );
};

StoreBranches.propTypes = {
  storebranches: PropTypes.object.isRequired,
  onchange: PropTypes.func
};

export default StoreBranches;
