/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// import Link from 'next/link';
import { MdSearch } from 'react-icons/md';
// import useTrackingQueue from '../../../../../../hooks/useTrackingQueue';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
// import generateHrefLink from '../../../../../../services/page/generateLinkHref';
import styles from '../suggestions-list.module.scss';
import { Link } from '@/components/atoms';
import { setProducts } from '@/redux/products/productsReducer';
import apiProducts from '@/api/products';
import useTrackingQueue from '@/hooks/useTrackingQueue';

const SuggestionItem = ({
  name = '',
  onClick = () => {},
  isGalleryPage = false,
  url = '/productos'
}) => {
  const { executeEvent } = useTrackingQueue();
  const dispatch = useDispatch();
  const storeId = useSelector((state) => state.store);
  const handleClick = async () => {
    // ejecutamos el evento "search" de Google Tag Manager
    const keyword = url.split('/');
    executeEvent(
      'search',
      `sug: ${
        (keyword[0] === 'adidas' && 'adidas') ||
        (keyword[1] === 'k' ? keyword[keyword.length - 1] : keyword[1])
      }`
    );
    onClick();
    if (isGalleryPage) {
      // Cambiar match a \/k\/ y sumarle 3 cuando cambie la url a la nueva
      const keywords = url.slice(url.match(/keywords=/).index + 9);
      const result = await apiProducts.get({ keywords }, 1, storeId);
      dispatch(
        setProducts({
          pagination: {
            total: result.data.total_pages,
            current: 1
          },
          // productos
          items: result.data.products,
          // filtros disponibles
          filtersOptions: result.data.filters,
          filtersActive: {
            slugs: []
          },

          loading: false
        })
      );
      history.pushState({}, '', `/${url}`);
    }
  };

  return isGalleryPage ? (
    <button
      onClick={() => handleClick(isGalleryPage)}
      style={{ background: 'transparent', border: 'none' }}
    >
      <div className={styles.item}>
        <MdSearch />
        <span>{name}</span>
      </div>
    </button>
  ) : (
    <Link
      href={`/${url}`}
      handleClick={handleClick}
    >
      <div className={styles.item}>
        <MdSearch />
        <span>{name}</span>
      </div>
    </Link>
  );
};

SuggestionItem.propTypes = {
  name: PropTypes.string,
  onClick: PropTypes.func,
  isGalleryPage: PropTypes.bool,
  url: PropTypes.string
};

export default SuggestionItem;
