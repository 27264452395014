'use client';

import { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Image from 'next/image';
import styles from './sideMenuDropdown.module.scss';
import NavbarSection from '@/components/molecules/NavbarSection';

import { Text } from '@/components/atoms';

const SideMenuDropdown = (props) => {
  const { onClose, faqs = [], title = '', selected = false } = props;
  const [open, setOpen] = useState(selected);
  const sectionRef = useRef(null);
  const faqsRef = useRef(null);
  const chevronRef = useRef(null);

  const openSection = () => {
    sectionRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    sectionRef.current.style.height = `${sectionRef.current.scrollHeight}px`;
    sectionRef.current.style.borderWidth = '2px';
    chevronRef.current.style.transform = 'rotate(90deg)';
  };

  const closeSection = () => {
    chevronRef.current.style.transform = 'rotate(0deg)';
    sectionRef.current.style.borderWidth = '0px';

    sectionRef.current.style.height = '0px';
  };

  const toggleSection = () => {
    if (!open) {
      openSection();
    } else {
      closeSection();
    }

    setOpen(!open);
  };

  useEffect(() => {
    if (open) {
      openSection();
      window.scrollTo({
        top: chevronRef.current.parentElement.offsetTop - 80,
        behavior: 'smooth'
      });
    }
  }, []);

  return (
    <div className={styles.container}>
      <button
        className={styles.dropdown}
        onClick={() => toggleSection()}
      >
        <Text
          textColor="white"
          weight="normal"
          textSize="s"
        >
          {title}
        </Text>
        <Image
          src="/logos/light-arrow.svg"
          alt="arrow"
          width={20}
          height={20}
          ref={chevronRef}
        />
      </button>
      <div
        ref={sectionRef}
        className={styles.info}
      >
        <div
          className={styles.faqs}
          ref={faqsRef}
        >
          {faqs.map((f) => (
            <NavbarSection
              key={f.name}
              title={f.name}
              items={f.items}
              allURL={f.url}
              onClose={onClose}
              sectionRef={sectionRef}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

SideMenuDropdown.propTypes = {
  faqs: PropTypes.array,
  title: PropTypes.string,
  selected: PropTypes.bool,
  onClose: PropTypes.func.isRequired
};

export default SideMenuDropdown;
