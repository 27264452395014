'use client';

import PropTypes from 'prop-types';
import SuggestionItem from './SuggestionItem';
import styles from './suggestions-list.module.scss';

const SuggestionsList = (props) => {
  const {
    data: { products, brands, sports },
    onClick = () => {},
    isGalleryPage = false
  } = props;
  const renderSuggestion = (items) =>
    items.map((x) => {
      return (
        <SuggestionItem
          isGalleryPage={isGalleryPage}
          key={x.url}
          {...x}
          onClick={onClick}
        />
      );
    });

  if (products.length > 0 || brands.length > 0 || sports.length > 0) {
    return (
      <div className={styles.container}>
        {brands.length > 0 && (
          <>
            <span className={styles.title}>Marcas</span>
            {renderSuggestion(brands)}
          </>
        )}

        {sports.length > 0 && (
          <>
            <span className={styles.title}>Deportes</span>
            {renderSuggestion(sports)}
          </>
        )}

        {products.length > 0 && (
          <>
            <span className={styles.title}>Productos</span>
            {renderSuggestion(products)}
          </>
        )}
      </div>
    );
  }

  return null;
};

SuggestionsList.propTypes = {
  data: PropTypes.object,
  onClick: PropTypes.func,
  isGalleryPage: PropTypes.bool
};

export default SuggestionsList;
