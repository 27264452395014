'use client';

import { useState } from 'react';
import PropTypes from 'prop-types';
import StarIcon from '@/components/molecules/PunctuationReview/StarIcon';
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import styles from './punctuationReview.module.scss';

const PunctuationReview = ({
  number,
  onHandleClickStar = () => {},
  isControlled = false
}) => {
  const [indexHover, setIndexHover] = useState(-1);

  const handleOnMouse = (index, leave) => {
    setIndexHover(leave ? -1 : index);
  };

  return (
    <div className={styles['review-container']}>
      <span className={styles['stars-review']}>
        {[1, 2, 3, 4, 5].map((starPosition) => {
          return (
            <StarIcon
              key={starPosition}
              full={indexHover >= starPosition}
              index={starPosition}
              onMouse={isControlled ? handleOnMouse : () => {}}
              onClick={isControlled ? onHandleClickStar : () => {}}
              number={Number(number)}
            />
          );
        })}
      </span>
    </div>
  );
};

PunctuationReview.propTypes = {
  number: PropTypes.number.isRequired,
  onHandleClickStar: PropTypes.func,
  isControlled: PropTypes.bool
};

export default PunctuationReview;
