'use client';

import isGender from '@/utils/isGender';

const routerPushWithQuery = (
  optionSelected,
  allCategoryOptions,
  ssrInfo,
  path
) => {
  path = path.replace(/\/p\/\d+/, '/p/1');
  const pageIndex = path.indexOf('/p/');
  const gender = isGender(optionSelected.slug);
  const isMicrosite = ssrInfo.storeId !== process.env.NEXT_PUBLIC_STORE_ID;
  if (gender) {
    path = path.replace(`/productos/`, `/`);
    const slugExistsIndex = path.indexOf(`/${gender}/`);
    allCategoryOptions.forEach((o) => {
      if (o.selected) {
        path = path.split(o.slug).join('').replace(/\/\//g, '/');
      }
    });
    if (slugExistsIndex < 0) {
      if (ssrInfo?.landing?.landingInfo?.landing) {
        const pathSplitWithLanding = path.split(
          `/${ssrInfo.landing.landingName}/`
        );
        path = `${isMicrosite ? `/${ssrInfo.storeId}` : ''}/${
          ssrInfo.landing.landingName
        }/${gender}/${pathSplitWithLanding[1]}`;
      } else {
        path = `/${optionSelected.slug}${path}`;
      }
    }

    if (path.split('/').length === 3) {
      path = `/productos${path}`;
    }
    return path;
  } else {
    const slugExistsIndex = path.indexOf(`/${optionSelected.slug}/`);

    if (slugExistsIndex < 0) {
      path = `${path.slice(0, pageIndex)}/${optionSelected.slug}/${path.slice(
        pageIndex + 1
      )}`;
    } else {
      path = path.split(optionSelected.slug).join('').replace(/\/\//g, '/');
    }
    return path;
  }
};
export default routerPushWithQuery;
