'use client';

import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Select from '@/components/atoms/Select';
import StoreBranchItem from './StoreBranchItem';
import styles from './storeBranchList.module.scss';
import { setActive } from '@/redux/map/mapReducer';

const StoreBranchList = ({ storebranches, map, onchange = () => {} }) => {
  const [citySelected, setCitySelected] = useState('Mendoza');
  const dispatch = useDispatch();

  const handleSelect = (e) => {
    onchange(50);
    setCitySelected(e.value);
  };

  useEffect(() => {
    if (storebranches[citySelected]) {
      const itemMap = storebranches[citySelected][0].address.address1
        .toLowerCase()
        .concat(
          ' ',
          storebranches[citySelected][0].address.address_number,
          ' ',
          'vaypol'
        )
        .replace(/\s/g, '_')
        .replace(/í/g, 'i');
      dispatch(
        setActive({
          address: itemMap,
          lat: storebranches[citySelected][0].latitude,
          lng: storebranches[citySelected][0].longitude
        })
      );
    }
  }, [citySelected]);

  const renderStorebranches = () =>
    storebranches[citySelected]?.map((x) => (
      <StoreBranchItem
        map={map}
        item={x}
        key={x.id}
      />
    ));

  const options = Object.keys(storebranches).map((e) => ({
    id: e,
    value: e,
    name: e
  }));

  return (
    <div className={styles.container}>
      {Object.keys(storebranches).length > 1 && (
        <Select
          id="select storeBranches"
          onChange={(e) => handleSelect(e)}
          options={options}
          defaultValue={citySelected}
          className={styles.select}
          titleColor="var(--color-grey)"
          color="var(--color-grey)"
          shadow
        />
      )}
      <div>
        <ul className={styles.list}>{renderStorebranches()}</ul>
      </div>
    </div>
  );
};

StoreBranchList.propTypes = {
  map: PropTypes.object.isRequired,
  storebranches: PropTypes.object.isRequired,
  onchange: PropTypes.func
};

export default StoreBranchList;
