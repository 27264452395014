import PropTypes from 'prop-types';
import { Image, Text, Link } from '@/components/atoms';
import styles from './categoryCard.module.scss';

const CategoryCard = ({ category = {} }) => {
  return (
    <Link
      className={styles.container}
      href={`/${category.slug}`}
      center
    >
      <div className={styles.image}>
        <Image
          alt={category.name}
          src={category.image?.url}
          priority
          cover
        />
      </div>
      <div className={styles.title}>
        <Text
          textCase="uppercase"
          variant="h2"
          textColor="white"
          weight="bold"
          textStyle={{
            textAlign: 'center',
            textShadow: '0 0 10px black',
            overflowWrap: 'break-word',
            fontSize: 'inherit',
            width: 'min-content'
          }}
        >
          {category.name}
        </Text>
      </div>
    </Link>
  );
};

CategoryCard.propTypes = {
  category: PropTypes.object
};

export default CategoryCard;
