'use client';

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import PropTypes from 'prop-types';
import { usePathname, useRouter } from 'next/navigation';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Select } from '@/components/atoms';
import { setPageLoading } from '@/redux/ssrInfo/ssrInfoReducer';

const SortSelect = (props) => {
  const { sortItems } = props;
  const { filtersActive } = useSelector((state) => state.products);
  const router = useRouter();
  const path = usePathname();
  const dispatch = useDispatch();
  // al seleccionar una opcion de "ordenar por"
  const getSortUrl = (value) => {
    const existingSort = path.split('/o/');

    const pageIndex = path.indexOf('/p/');
    if (existingSort.length > 1) {
      return `${existingSort[0]}/o/${value}/${existingSort[1]
        .split('/')
        .slice(1)
        .join('/')}`;
    }
    return `${path.slice(0, pageIndex)}/o/${value}${path.slice(pageIndex)}`;
  };
  const placeholder = useMemo(
    () =>
      sortItems.find((item) => item.id === filtersActive.ordenar)?.name ||
      'Ordenar por',
    [filtersActive]
  );
  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <div>
        <Select
          id="sortSelect_Filters"
          brColor="white"
          color="white"
          placeholder={placeholder}
          options={sortItems}
          onSelect={(option) => {
            dispatch(setPageLoading(true));
            router.push(getSortUrl(option.value));
          }}
          variant="outlined"
          className="halfWidth"
        />
      </div>
    </div>
  );
};
SortSelect.propTypes = { sortItems: PropTypes.array.isRequired };

export default SortSelect;
