'use client';

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useRef, useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import cn from 'classnames';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import Icon from '@/components/atoms/Icon';
import useFixBody from '@/hooks/useFixBody';

const GeneralModal = ({
  children,
  onClose = () => {},
  closeColor = 'black',
  withoutCloseIcon = false,
  modalStyles = {},
  fullModal = false,
  withAnimations = false
}) => {
  const [isClosing, setIsClosing] = useState(false);
  const modalRef = useRef(null);

  const modalClass = cn({
    [styles.modal]: true,
    [styles.fullModal]: fullModal,
    [styles.open]: !isClosing && withAnimations,
    [styles.close]: isClosing && withAnimations
  });

  const closeClass = cn({
    [styles.iconBtn]: true
  });

  useFixBody();

  useEffect(() => {
    if (isClosing) {
      const timer = setTimeout(() => {
        onClose();
      }, 300); // Duración de la animación de cierre en milisegundos

      return () => clearTimeout(timer);
    }
  }, [isClosing, onClose]);

  const handleButtonClose = () => {
    setIsClosing(true);
  };

  const handleClose = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      setIsClosing(true);
    }
    e.stopPropagation();
  };

  const modalContent = (
    <div
      ref={modalRef}
      className={modalClass}
      style={modalStyles}
    >
      {!withoutCloseIcon && (
        <button
          onClick={handleButtonClose}
          className={closeClass}
          aria-label="close"
          style={modalStyles?.closeStyles || undefined}
        >
          <Icon
            color={closeColor}
            type="close"
          />
        </button>
      )}

      {children}
    </div>
  );

  return createPortal(
    fullModal ? (
      modalContent
    ) : (
      <div
        className={styles.backdrop}
        onClick={handleClose}
      >
        {modalContent}
      </div>
    ),
    document.body
  );
};

GeneralModal.propTypes = {
  children: PropTypes.node.isRequired,
  closeColor: PropTypes.string,
  onClose: PropTypes.func,
  withoutCloseIcon: PropTypes.bool,
  fullModal: PropTypes.bool,
  withAnimations: PropTypes.bool,
  modalStyles: PropTypes.object
};

export default GeneralModal;
