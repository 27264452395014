import PropTypes from 'prop-types';
import styles from './column.module.scss';

const Column = ({ children, gap = '3rem' }) => (
  <div
    className={styles.column}
    style={{ gap }}
  >
    {children}
  </div>
);

Column.propTypes = {
  children: PropTypes.node.isRequired,
  gap: PropTypes.string
};

export default Column;
