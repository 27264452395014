'use client';

import { useDispatch } from 'react-redux';
import { useRouter } from 'next/navigation';
import { destroyCookie } from '@/services/cookies';
import { setUser } from '@/services/sentry';
import { checkoutRestore } from '@/redux/checkout/checkoutReducer';
import { cartRestore } from '@/redux/cart/cartReducer';
// import { setCookies } from '@/services/vaypolCookies/conections';
import pushToMicroSite from '@/utils/pushToMicroSite';
import cleanFavorites from '@/utils/cleanFavorites/cleanFavorites';

const useLogout = () => {
  const router = useRouter();
  const dispatch = useDispatch();
  return (checkout = false, redirect = false) => {
    setUser({ email: null });
    destroyCookie('token');
    destroyCookie('refreshToken');
    dispatch(checkoutRestore());
    localStorage.removeItem('user');
    localStorage.removeItem('marketingAutomationID');
    cleanFavorites();

    // si no vengo desde checkout reinicio el carrito tambien
    if (!checkout) {
      destroyCookie('cartToken');
      dispatch(cartRestore());
    }
    // setCookies(true);

    if (redirect) router.push(pushToMicroSite(true));
  };
};

export default useLogout;
