'use client';

/* eslint-disable camelcase */
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { usePathname, useRouter } from 'next/navigation';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { Container, Divider, Text } from '@/components/atoms';
import useDeviceType from '@/hooks/useDeviceType';
import ScrollableResume from './ScrollableResume';
import styles from './paymentResume.module.scss';
import variables from '@/styles/variables';
import parsePriceToNumber from '@/utils/parsePriceToNumber';

const PaymentResume = ({ children, withInstallements = false }) => {
  const { isDesktop } = useDeviceType();
  const router = useRouter();
  const pathname = usePathname();
  const cart = useSelector((state) => state.cart);
  const installment = useSelector(
    (state) => state.checkout.payment?.installment
  );
  const secondInstallments = useSelector(
    (state) => state.checkout.secondPayment?.installment
  );
  const [scrollOpen, setScrollOpen] = useState(false);

  const paymentItems = cn({
    [styles.line]: true,
    [styles.paymentDesktopItem]: true
  });
  useEffect(() => {
    if (!cart.token) {
      router.push('/');
    }
  }, []);

  const parse = (number) => {
    const result = new Intl.NumberFormat('de-DE', {
      style: 'currency',
      currency: 'ARS'
    }).format(number.toFixed(2));
    return result.split(',')[0].replace('ARS', '');
  };

  const openScroll = () => {
    setScrollOpen(!scrollOpen);
  };
  const installmentsAdded =
    (parsePriceToNumber(installment?.final_price) || 0) +
    (parsePriceToNumber(secondInstallments?.final_price) || 0);

  return (
    <div style={{ backgroundColor: 'var(--color-black)', width: '100%' }}>
      {!isDesktop && (
        <>
          {children}
          <div className={styles.resumeContainer}>
            <div
              draggable
              // for mobile
              onTouchStart={() => {
                openScroll();
              }}
              // for desktop
              onDragStart={() => {
                openScroll();
              }}
              style={{
                height: '30px',
                paddingTop: '10px',
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <Divider
                color="white"
                style={{ width: '20%', alignSelf: 'center', height: '2px' }}
              />
            </div>
            <ScrollableResume
              open={scrollOpen}
              withInstallements={withInstallements}
            />
            <div className={styles.paymentMobile}>
              <Text textColor="white">Pagás: </Text>
              <Text
                textColor="white"
                weight="bold"
              >
                {(installment || secondInstallments) &&
                (pathname === '/checkout/review' || withInstallements) ? (
                  `$ ${parse(installmentsAdded)}`
                ) : (
                  <span>$ {cart.total?.toString().split(',')[0]}</span>
                )}
              </Text>
            </div>
          </div>
        </>
      )}
      {isDesktop && (
        <div className={styles.container}>
          {children}
          <div className={styles.boxResize}>
            <Container
              className="isBackgrounded"
              style={{ height: 'auto' }}
            >
              <div className={styles.paymentDesktop}>
                <div>
                  <Text textColor="white">Resumen de compra</Text>
                  <Divider
                    style={{ marginBlock: '1rem' }}
                    color={variables['color-grey-light']}
                  />
                </div>
                <ScrollableResume withInstallements={withInstallements} />
                <div>
                  <div className={paymentItems}>
                    <Text textColor="white">Pagás:</Text>
                    <Text
                      textColor="white"
                      weight="bold"
                    >
                      {(installment || secondInstallments) &&
                      (pathname === '/checkout/review' || withInstallements) ? (
                        `$ ${parse(installmentsAdded)}`
                      ) : (
                        <span>$ {cart.total?.toString().split(',')[0]}</span>
                      )}
                    </Text>
                  </div>
                </div>
              </div>
            </Container>
          </div>
        </div>
      )}
    </div>
  );
};

PaymentResume.propTypes = {
  children: PropTypes.node.isRequired,
  withInstallements: PropTypes.bool
};

export default PaymentResume;
