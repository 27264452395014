'use client';

/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

// import { useRouter } from 'next/navigation';
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import cn from 'classnames';
import { Button, Icon, Link, Text } from '@/components/atoms';
import styles from './cartModal.module.scss';
import useCheckoutRouter from '@/hooks/checkoutRouter';
import useOutsideClick from '@/hooks/useOutsideClick';
import useTrackingQueue from '@/hooks/useTrackingQueue';
import checkoutAPI from '@/api/checkout';
import { loadCartInfo } from '@/redux/cart/cartReducer';
import useFixBody from '@/hooks/useFixBody';
import parsePriceToNumber from '@/utils/parsePriceToNumber';

// importar de forma dinamica CartItem
const CartItem = dynamic(() => import('@/components/molecules/CartItem'));

const CartModal = ({ close }) => {
  const cart = useSelector((state) => state.cart);
  const dispatch = useDispatch();
  const checkout = useCheckoutRouter('home');
  const [loading, setLoading] = useState(false);
  // const router = useRouter();
  const ref = useRef(null);
  const backRef = useRef(null);
  const { executeEvent } = useTrackingQueue();
  // useEffect(() => {
  //   // router.events.on('routeChangeComplete', close);

  //   return () => {
  //     router.events.off('routeChangeComplete', close);
  //   };
  // }, [close]);
  const installments = (total) => {
    const string = new Intl.NumberFormat('de-DE', {
      style: 'currency',
      currency: 'ARS'
    }).format((parseFloat(parsePriceToNumber(total)) / 6).toFixed(2));
    return string.split(',')[0].replace('ARS', '');
  };

  useOutsideClick(ref, () => {
    if (cart.showModal) {
      close();
    }
  });

  useFixBody();

  const containerStyles = cn({
    [styles.container]: true,
    scrollBarStyles: true
  });

  const goToCheckout = async () => {
    setLoading(true);
    if (cart.state === 'cart') {
      const next = await checkoutAPI.advanceToNext();
      if (next.success) {
        dispatch(loadCartInfo(next.data.attributes));
      }
    }
    executeEvent('beginCheckout');
    close();
    checkout();
    setLoading(false);
  };

  const cartTotal =
    (parsePriceToNumber(cart?.item_total) || 0) +
    (parsePriceToNumber(cart?.promo_total) || 0);
  const parse = (number) => {
    const result = new Intl.NumberFormat('de-DE', {
      style: 'currency',
      currency: 'ARS'
    }).format(number.toFixed(2));
    return result.split(',')[0].replace('ARS', '');
  };

  return (
    <>
      <div
        ref={backRef}
        className={styles.back}
      />
      <div
        ref={ref}
        className={containerStyles}
      >
        <div className={styles.title}>
          <div style={{ display: 'flex' }}>
            <Text
              textStyle={{ fontSize: '20px', marginRight: '5px' }}
              textColor="var(--color-white-absolute)"
              weight="semibold"
            >
              Mi carrito
            </Text>
          </div>
          <button
            id="closeCartModal"
            onClick={() => close()}
            style={{
              background: 'transparent',
              border: 'none',
              cursor: 'pointer',
              width: '1rem'
            }}
            aria-label="close"
          >
            <Icon
              type="close"
              width="fit-content"
              fontSize={30}
              color="var(--color-white-absolute)"
            />
          </button>
        </div>
        {cart.line_items?.length > 0 ? (
          <>
            <div className={styles.items}>
              {cart.line_items.map((item) => (
                <CartItem
                  key={`${item.name} ${item.variant}`}
                  item={item}
                  close={close}
                />
              ))}
            </div>
            <div className={styles.resumen}>
              <Text
                textStyle={{ marginBottom: '.5rem' }}
                textColor="white"
                weight="semibold"
                textSize="l"
              >
                Total: <span>$ {parse(cartTotal)}</span>
              </Text>
              <Text textColor="var(--color-primary)">
                ó 6 cuotas de ${installments(parse(cartTotal))} sin interés
              </Text>
            </div>
            <Button
              label="Comprar"
              variant="primary"
              withOutPadding
              textWeight="semibold"
              style={{
                border: 'transparent',
                height: '40px',
                marginBottom: '20px',
                backgroundColor: 'var(--color-primary-alternative)'
              }}
              onClick={goToCheckout}
              // textColor="red"
              loading={loading}
            />
          </>
        ) : (
          <div className={styles.empty}>
            <Text>
              El carrito esta vacío, agregá algún item para continuar la compra
            </Text>
            <button
              style={{
                background: 'transparent',
                border: 'none'
              }}
              onClick={close}
            >
              <Link
                primary
                href="/productos"
                linkStyle={{ marginTop: '10px' }}
              >
                <Text colored>Continuar comprando</Text>
              </Link>
            </button>
          </div>
        )}
      </div>
    </>
  );
};

CartModal.propTypes = {
  close: PropTypes.func.isRequired
};

export default CartModal;
