'use client';

/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './index.module.scss';
import { Icon, Link, Text } from '@/components/atoms';

const Check = ({ selected = false }) =>
  selected ? (
    <div
      style={{
        height: '22px',
        width: '22px',
        borderRadius: '6px',
        background: 'var(--color-primary)'
      }}
    >
      <div style={{ padding: '.4rem' }}>
        <Icon
          type="close"
          center
          fontSize={18}
        />
      </div>
    </div>
  ) : (
    <div
      style={{
        border: '1px solid white',
        height: '22px',
        width: '22px',
        borderRadius: '6px'
      }}
    />
  );

const FilterOption = (props) => {
  const { name, count, href } = props;
  const [_selected, setSelected] = useState(props.selected);

  useEffect(() => setSelected(props.selected), [props.selected]);

  const classNameIcon = cn({
    [styles.icon]: true,
    [styles['icon--checked']]: _selected
  });
  return (
    <li>
      <Link
        prefetch
        className={styles.container}
        href={href}
        absolutePath
      >
        <div className={classNameIcon}>
          {!_selected && <Check />}
          {_selected && <Check selected />}
          <div className={styles.tickFill} />
        </div>

        <Text
          color="white"
          textStyle={{ fontSize: '15px', textAlign: 'start' }}
        >
          {name} ({count})
        </Text>
      </Link>
    </li>
  );
};
Check.propTypes = {
  selected: PropTypes.bool
};

FilterOption.propTypes = {
  count: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  selected: PropTypes.bool
};

export default FilterOption;
