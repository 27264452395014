'use client';

/* eslint-disable react/no-danger */
import PropTypes from 'prop-types';
import cn from 'classnames';
import { BiMinus, BiPlus } from 'react-icons/bi';
import { useState, useRef, useEffect } from 'react';
import styles from './faqSection.module.scss';
import { Divider, Text } from '@/components/atoms';

const FaqSection = (props) => {
  const {
    description = '',
    title = '',
    selected = false,
    onchange = () => {}
  } = props;
  const [open, setOpen] = useState(selected);
  const infoRef = useRef(null);
  const verticalRef = useRef(null);
  const horizontalRef = useRef(null);
  const infoFaqClass = cn({
    [styles.faqInfo]: true
  });

  const openInfo = () => {
    if (!open) {
      infoRef.current.style.height = `${infoRef.current.scrollHeight}px`;
      verticalRef.current.style.transform = 'rotate(360deg)';
      horizontalRef.current.style.opacity = '0';

      horizontalRef.current.style.transform = 'rotate(360deg)';
      onchange(infoRef.current.scrollHeight);
    } else {
      verticalRef.current.style.transform = 'rotate(0deg)';
      horizontalRef.current.style.opacity = '1';

      horizontalRef.current.style.transform = 'rotate(0deg)';
      infoRef.current.style.height = '0px';
      onchange(-infoRef.current.scrollHeight);
    }
    setOpen(!open);
  };

  useEffect(() => {
    if (selected) openInfo();
  }, [infoRef.current]);

  return (
    <div className={styles.faq}>
      <div className={styles.titleSection}>
        <button
          className={styles.faqTitle}
          onClick={() => openInfo()}
        >
          <Text>{title}</Text>
          <div className={styles.icon}>
            <div
              ref={verticalRef}
              className={styles.vertical}
            >
              <BiMinus fontSize={15} />
            </div>
            <div
              ref={horizontalRef}
              className={styles.horizontal}
            >
              <BiPlus fontSize={15} />
            </div>
          </div>
        </button>
        <Divider color="grey" />
      </div>
      <div
        ref={infoRef}
        className={infoFaqClass}
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </div>
  );
};

FaqSection.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string,
  selected: PropTypes.bool,
  onchange: PropTypes.func
};

export default FaqSection;
