import Spinner from '@/components/molecules/Spinner';
import styles from './index.module.scss';
import Text from '@/components/atoms/Text';
import Divider from '@/components/atoms/Divider';

const CartModalLoading = () => {
  return (
    <>
      <div className={styles.back} />
      <div className={styles.container}>
        <div className={styles.title}>
          <Text
            textStyle={{ fontSize: '20px', marginRight: '5px' }}
            textColor="var(--color-white-light)"
            weight="bolder"
          >
            Cargando...
          </Text>
        </div>
        <Divider color="grey" />

        <div className={styles.loader}>
          <Spinner />
        </div>
      </div>
    </>
  );
};

export default CartModalLoading;
