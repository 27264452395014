'use client';

import PropTypes from 'prop-types';
import { useRouter } from 'next/navigation';
import Column from '@/components/atoms/Column';
import Container from '@/components/atoms/Container';
import Icon from '@/components/atoms/Icon';
import { Title } from '@/components/molecules';

const CheckoutStylesWrapper = ({ children, title }) => {
  const router = useRouter();
  return (
    <div style={{ width: '100%', padding: '1.5rem', marginBottom: '40px' }}>
      <Container className="isBackgrounded ">
        <Column gap="0px">
          <Title
            label={title}
            icon={
              <Icon
                type="arrow"
                reverse
                colored
                width="1.5rem"
              />
            }
            iconClick={() => router.back()}
            labelWeight="bold"
          />
          {children}
        </Column>
      </Container>
    </div>
  );
};

CheckoutStylesWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]).isRequired,
  title: PropTypes.string.isRequired
};

export default CheckoutStylesWrapper;
