import PropTypes from 'prop-types';
import { Divider, Text } from '@/components/atoms';
import styles from './benefitItem.module.scss';
import useDeviceType from '@/hooks/useDeviceType';

const BenefitItem = ({ logo, text }) => {
  const { isDesktop } = useDeviceType();

  return (
    <div className={styles.item}>
      {isDesktop && (
        <Divider
          color="var(--color-white)"
          vertical
          inherit
          otherSize="2px"
          colored
          style={{ borderRadius: '50px', minWidth: '2px' }}
        />
      )}
      <div className={styles.container}>
        {logo}
        <Text
          variant="span"
          textSize="s"
          textColor="var(--color-white-absolute)"
        >
          {text}
        </Text>
      </div>
    </div>
  );
};

BenefitItem.propTypes = {
  logo: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired
};

export default BenefitItem;
