'use client';

/* eslint-disable react/jsx-no-comment-textnodes */
import PropTypes from 'prop-types';
import { CgChevronDoubleRight, CgChevronDoubleLeft } from 'react-icons/cg';
import { useState, useEffect, useRef } from 'react';
import styles from './logoSlider.module.scss';

const LogoSlider = (props) => {
  const { children, interval = 4000 } = props;
  const [containerWidth, setContainerWidth] = useState(0);
  const [isAutoplay, setAutoplay] = useState(true);
  const [index, setIndex] = useState(0);
  const containerRef = useRef(null);
  let slidesToShow = 5;
  let itemWidth = containerWidth / slidesToShow;
  let contentWidth = containerRef.current?.offsetWidth;
  let totalIndex = Math.ceil(children.length / slidesToShow) - 1;
  if (containerRef.current?.children[0]) {
    itemWidth = containerRef.current?.children[0].offsetWidth;
    contentWidth = containerRef.current?.offsetWidth;
    slidesToShow = contentWidth / itemWidth;
    totalIndex = Math.ceil(children.length / slidesToShow) - 1;
    const carousel = document.getElementById('logoCarousel');
    carousel.style.width = `${Math.floor(slidesToShow) * 146}px`;
  }

  useEffect(() => {
    if (containerRef.current) {
      setContainerWidth(containerRef.current.offsetWidth);
      containerRef.current.scrollTo(0, 0);

      // carrousel.style.width = Math.floor(carrousel.style.width / 146) * 146;
    }
  }, [containerRef, slidesToShow]);

  useEffect(() => {
    if (containerRef.current && isAutoplay) {
      const int = setInterval(() => {
        setIndex(() => {
          if (index + 1 > totalIndex) return 0;
          return index + 1;
        });
      }, interval);

      return () => {
        clearInterval(int);
      };
    }

    return () => {};
  }, [containerRef, isAutoplay, totalIndex]);

  const transition = () => {
    let value = itemWidth * slidesToShow * index;

    if (value < 0) {
      value = 0;
    }

    containerRef.current.scrollTo({
      left: value,
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    transition();
  }, [index]);

  const handleClickArrow = (type) => {
    switch (type) {
      case 'next':
        setIndex(() => {
          if (index + 1 > totalIndex) return 0;
          return index + 1;
        });
        break;

      case 'back':
        setIndex(() => {
          if (index === 0) return totalIndex;
          return index - 1;
        });
        break;

      default:
        break;
    }

    setAutoplay(false);
  };
  return (
    <div className={styles.slider}>
      {/*   eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <div
        onClick={() => handleClickArrow('back')}
        style={{ cursor: 'pointer', paddingRight: '15px' }}
      >
        <CgChevronDoubleLeft
          fontSize={30}
          color="#FFBE1E"
          style={{ position: 'absolute', left: '15px', height: '50px' }}
        />
        <CgChevronDoubleLeft
          color="#FFBE1E"
          style={{ height: '50px' }}
          fontSize={30}
        />
      </div>
      <div
        id="logoCarousel"
        style={{ overflowX: 'hidden' }}
      >
        <div
          ref={containerRef}
          className={styles.carousel}
          style={{ width: '100%', flex: 1 }}
        >
          {children}
        </div>
      </div>
      {/*   eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <div
        onClick={() => handleClickArrow('next')}
        style={{ display: 'flex', cursor: 'pointer' }}
      >
        <CgChevronDoubleRight
          color="#FFBE1E"
          fontSize={30}
          style={{ height: '50px' }}
        />
        <CgChevronDoubleRight
          color="#FFBE1E"
          fontSize={30}
          style={{ height: '50px', position: 'relative', left: '-15px' }}
        />
      </div>
    </div>
  );
};

LogoSlider.propTypes = {
  children: PropTypes.array.isRequired,
  interval: PropTypes.number
};

export default LogoSlider;
