'use client';

/* eslint-disable */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
// import { removeFilter, setFilter } from '../../../../redux';
import routerPushWithQuery from '@/utils/filters/routerPushWithQuery';
import FilterOption from '../FilterOption';
import FilterOptionSize from '../FilterOptionSize';
import styles from './index.module.scss';
import { setPageLoading } from '@/redux/ssrInfo/ssrInfoReducer';
import { useRouter } from 'next/navigation';

const FilterItem = (props) => {
  const { options, sizes = false } = props;
  const { filtersActive } = useSelector((state) => state.products);
  const [open] = useState(true);
  const dispatch = useDispatch();
  const router = useRouter();
  const ssrInfo = useSelector((state) => state.ssrInfo);
  let path = usePathname();
  const getSizeUrl = (option) => {
    const pageIndex = path.indexOf('/p/');
    const pathSplitWithSize = path.split('/t/');
    // El talle existe
    if (filtersActive.talle.find((sz) => sz === option.slug)) {
      if (filtersActive.talle.length === 1) {
        return path.replace(/\/t\/\d+/, '');
      } else {
        return path
          .replace(new RegExp(`,?${option.slug}(?=\\.|,)`), '')
          .replace(/\.$/, '');
      }
    }
    // Existen talles en la url
    if (pathSplitWithSize.length > 1) {
      return `${pathSplitWithSize[0]}/t/${filtersActive.talle.join(',')},${
        option.slug
      }/${pathSplitWithSize[1].split('/').slice(1).join('/')}`;
    }

    return `${path.slice(0, pageIndex)}/t/${option.slug}/${path.slice(
      pageIndex + 1
    )}`;
  };
  const renderOptions = () =>
    options.map((x, i) => {
      if (sizes) {
        return (
          <FilterOptionSize
            onClick={() => {
              dispatch(setPageLoading(true));
              router.push(getSizeUrl(o));
            }}
            key={i}
            {...x}
          />
        );
      }
      return (
        <FilterOption
          key={x.slug}
          href={routerPushWithQuery(o, optionsSorted, ssrInfo, path)}
          {...x}
        />
      );
    });

  const classList = cn({
    [styles.list]: true,
    [styles['list--row']]: !!sizes
  });

  return (
    <li className={styles.container}>
      {open && <ul className={classList}>{renderOptions()}</ul>}
    </li>
  );
};
FilterItem.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
  multiple: PropTypes.bool,
  sizes: PropTypes.bool
};

export default FilterItem;
