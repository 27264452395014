import { useRef, useState, useEffect } from 'react';
import { usePathname, useRouter } from 'next/navigation';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import routerPushWithQuery from '@/utils/filters/routerPushWithQuery';
import Chevron from '../Chevron';
import Divider from '../Divider';
import Text from '../Text';
import styles from './index.module.scss';
import FilterOptionSize from '@/components/organisms/Filters/FilterSort/FilterOptionSize';
import FilterOption from '@/components/organisms/Filters/FilterSort/FilterOption';
import { setPageLoading } from '@/redux/ssrInfo/ssrInfoReducer';

const SelectFilter = ({ label, options, sizes = false }) => {
  const [close, setClose] = useState(false);
  const router = useRouter();
  const dispatch = useDispatch();
  const ref = useRef(null);
  // const optionRef = useRef(null);
  const { filtersActive } = useSelector((state) => state.products);
  const ssrInfo = useSelector((state) => state.ssrInfo);
  const sizesTable = {
    '2XS': 1,
    XXS: 2,
    XS: 3,
    S: 4,
    M: 5,
    L: 6,
    XL: 7,
    XXL: 8,
    '2XL': 9
  };
  const optionsSorted = [...(options || [])].sort((a, b) => {
    if (sizesTable[a.name] && sizesTable[b.name]) {
      return sizesTable[a.name] - sizesTable[b.name];
    }
    // Si solo uno de los nombres está en el objeto de orden, ese va primero
    else if (sizesTable[a.name]) {
      return -1;
    } else if (sizesTable[b.name]) {
      return 1;
    }
    // Si ninguno de los nombres está en el objeto de orden, orden alfabético
    return a.name.localeCompare(b.name);
  });

  useEffect(() => {
    const element = ref.current;
    // const option = optionRef.current;
    if (close) {
      element.style.transition = '0.5s linear';
      element.style.maxHeight = '0';
      // element.style.overflow = 'hidden';
    } else {
      element.style.transition = '0.5s linear';
      element.style.maxHeight = `540px`;
      // element.style.overflowY = 'scroll';
    }
  }, [close, ref.current]);
  const path = usePathname();

  const getSizeUrl = (option) => {
    const pageIndex = path.indexOf('/p/');
    const pathSplitWithSize = path.split('/t/');
    // El talle existe
    if (filtersActive.talle.find((sz) => sz === option.slug)) {
      if (filtersActive.talle.length === 1) {
        return path.replace(/\/t\/\d+/, '');
      } else {
        return path
          .replace(new RegExp(`,?${option.slug}(?=\\.|,)`), '')
          .replace(/\.$/, '');
      }
    }
    // Existen talles en la url
    if (pathSplitWithSize.length > 1) {
      return `${pathSplitWithSize[0]}/t/${filtersActive.talle.join(',')},${
        option.slug
      }/${pathSplitWithSize[1].split('/').slice(1).join('/')}`;
    }

    return `${path.slice(0, pageIndex)}/t/${option.slug}/${path.slice(
      pageIndex + 1
    )}`;
  };
  const getOptionsSelected = () => {
    const optionsSelected = optionsSorted.filter(({ selected }) => selected);
    let stringToReturn = '';
    if (optionsSelected.length === 0) return stringToReturn;
    optionsSelected?.forEach(({ slug }) => {
      stringToReturn += `${stringToReturn.length ? '/' : ''}${slug}`;
    });
    return stringToReturn;
  };
  return (
    <li className={styles.container}>
      <button
        className={styles.selectBtn}
        onClick={() => setClose(!close)}
      >
        <div className={styles.textInBtn}>
          <Text
            textColor="white"
            weight="semibold"
          >
            {label}
          </Text>
          <Text
            textColor="white"
            weight="light"
            textStyle={{ fontSize: '.8rem' }}
          >
            {getOptionsSelected()}
          </Text>
        </div>

        <Chevron
          size={28}
          open={!close}
          color="white"
          timeOfRotate="0.5s"
        />
      </button>
      <Divider color="var(--color-grey-lighter)" />
      <ul
        ref={ref}
        className={styles.options}
        style={{
          flexDirection: `${sizes ? 'row' : 'column'}`,
          flexWrap: `${sizes ? 'wrap' : 'nowrap'}`,
          gap: `${sizes ? '15px' : '0px'}`
        }}
      >
        {optionsSorted.map((o) =>
          sizes ? (
            <FilterOptionSize
              key={o.slug}
              onClick={() => {
                dispatch(setPageLoading(true));
                router.push(getSizeUrl(o));
              }}
              {...o}
            />
          ) : (
            <FilterOption
              key={o.slug}
              href={routerPushWithQuery(o, optionsSorted, ssrInfo, path)}
              {...o}
            />
          )
        )}
      </ul>
    </li>
  );
};

SelectFilter.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  type: PropTypes.oneOf(['gender', 'slugs', 'talle']).isRequired,
  multiple: PropTypes.bool,
  sizes: PropTypes.bool
};

export default SelectFilter;
