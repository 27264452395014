/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { usePathname } from 'next/navigation';
import styles from './scrollableResume.module.scss';
import { Divider, Image, Text } from '@/components/atoms';

const ScrollableResume = ({ open = true, withInstallements = false }) => {
  const cart = useSelector((state) => state.cart);
  const installment = useSelector(
    (state) => state.checkout.payment.installment
  );
  const secondInstallment = useSelector(
    (state) => state.checkout.secondPayment?.installment
  );
  const pathname = usePathname();
  const scrollClasses = cn({
    [styles.container]: true,
    [styles.close]: !open
  });

  // eslint-disable-next-line arrow-body-style
  const renderQuantityText = () => {
    return cart.line_items?.length > 1
      ? `Productos(${cart.line_items.length}):`
      : 'Producto:';
  };

  return (
    <div className={scrollClasses}>
      <div className={styles.lineItems}>
        {cart.line_items.map((i) => (
          <div
            key={`${i.id} `}
            style={{ display: 'flex' }}
          >
            <div className={styles.image}>
              <Image
                src={i?.thumbnail.url}
                alt={i?.name}
              />
            </div>
            <div>
              <Text
                textSize="xs"
                className={styles.title}
              >
                {i.name}
              </Text>
              <Text
                textSize="xs"
                textColor="var(--color-primary)"
                className={styles.color}
              >
                {i.options_text?.split('/').join(' / ')}
              </Text>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: '10px',
                  marginBottom: '20px'
                }}
              >
                <Text textSize="xs">
                  $
                  {typeof i.price === 'string'
                    ? i.price.split(',')[0]
                    : i.prices.sale_price
                    ? i.prices.sale_price.split(',')[0]
                    : i.prices.original.split(',')[0]}
                </Text>
                <Text
                  textSize="xs"
                  textStyle={{
                    fontWeight: 'lighter',
                    marginLeft: '3px'
                  }}
                >
                  x {i.quantity} Unidad{i.quantity > 1 && 'es'}
                </Text>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div
        className={styles.resumeLine}
        style={{ marginTop: '15px' }}
      >
        <Text>{renderQuantityText()}</Text>
        <Text weight="light">${cart.item_total?.split(',')[0]}</Text>
      </div>
      {cart.promo_total !== '0,00' && (
        <div
          className={styles.resumeLine}
          style={{ marginTop: '15px' }}
        >
          <Text>Descuentos: </Text>
          <Text weight="light">${cart.promo_total?.split(',')[0]}</Text>
        </div>
      )}
      <div className={styles.resumeLine}>
        <Text>Envio:</Text>
        <Text
          weight="light"
          textColor={
            cart.ship_total === '0,00'
              ? 'var(--color-primary)'
              : 'var(--color-white-absolute)'
          }
        >
          {cart.ship_total !== '0,00'
            ? `$${cart.ship_total?.split(',')[0]}`
            : 'Gratis'}
        </Text>
      </div>
      {(pathname === '/checkout/review' || withInstallements) &&
        installment?.interest_rates > 0 && (
          <div className={styles.resumeLine}>
            <Text textStyle={{ display: 'flex' }}>
              CFT {installment.interest_rates}%<Text textColor="black">. </Text>
              {secondInstallment && <Text textSize="xs">(1°) </Text>}
            </Text>
            {/* costo del financiamiento */}
            <Text weight="light">
              ${installment.interest_amount.split(',')[0]}
            </Text>
          </div>
        )}
      {(pathname === '/checkout/review' || withInstallements) &&
        secondInstallment?.interest_rates > 0 && (
          <div className={styles.resumeLine}>
            <Text textStyle={{ display: 'flex' }}>
              CFT {secondInstallment.interest_rates}%{' '}
              <Text textColor="black">. </Text>
              <Text textSize="xs">(2°) </Text>
            </Text>
            {/* costo del financiamiento */}
            <Text weight="light">
              ${secondInstallment.interest_amount.split(',')[0]}
            </Text>
          </div>
        )}
      <Divider
        style={{ marginTop: '15px' }}
        color="white"
      />
    </div>
  );
};

ScrollableResume.propTypes = {
  open: PropTypes.bool,
  withInstallements: PropTypes.bool
};

export default ScrollableResume;
